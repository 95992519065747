export const config = {
  projects: [
    {
      pathname: "language-of-silence",
      name: "Language of silence",
      description: [
        "Covid-19 made the whole world change plans, made us put our super important lives on pause, stop for a second and look around to finally notice where we are, what we are doing, and where we are running all the time. The difference of my situation is that I had to do it not at home surrounded by my family but in Italy, a place which was supposed to be a touristic sight to me but became my short term shelter.",
        "In cases like this you don’t have a choice, even with zero knowledge of language you can’t avoid becoming a local in only one day of sharing the panic and fear. Suddenly you find yourself standing in a long open air queue to some grocery store, each person standing in 1 meter distance from the other, all silent, and for a second it seems like this silence is a language itself, where you don’t need words to understand what is the elderly man in front of you thinking or what is the girl with a dog behind you thinking, here and now you realize how difficult it is to understand mentality of foreigners and how easy it is to understand mentality of these people standing right next to you when you share the same life changing situation.",
        "On March 15 a governmental evacuation flight took us back home to Armenia where we were put on a 14 days mandatory isolation, quite a long time to think, and what I realized is that quarantine will come to an end some day, the virus will pass, but what we need to keep from these days is the ability to hear each other’s silence, wherever we are, whoever is near.",
      ],
      photos: [
        {
          variant: "lof_01",
        },
        {
          variant: "lof_02",
        },
        {
          variant: "lof_03",
        },
        {
          variant: "lof_04",
        },
        {
          variant: "lof_05",
        },
        {
          variant: "lof_06",
        },
        {
          variant: "lof_07",
        },
        {
          variant: "lof_08",
        },
        {
          variant: "lof_09",
        },
        {
          variant: "lof_10",
        },
        {
          variant: "lof_11",
        },
        {
          variant: "lof_12",
        },
        {
          variant: "lof_13",
        },
        {
          variant: "lof_14",
        },
        {
          variant: "lof_15",
        },
        {
          variant: "lof_16",
        },
        {
          variant: "lof_17",
        },
        {
          variant: "lof_18",
        },
        {
          variant: "lof_19",
        },
        {
          variant: "lof_20",
        },
        {
          variant: "lof_21",
        },
        {
          variant: "lof_22",
        },
        {
          variant: "lof_23",
        },
        {
          variant: "lof_24",
        },
      ],
    },
    {
      pathname: "garnik-papik",
      name: "Garnik Papik",
      photos: [
        {
          variant: "gp_01",
        },
        {
          variant: "gp_02",
        },
        {
          variant: "gp_03",
        },
        {
          variant: "gp_04",
        },
        {
          variant: "gp_05",
        },
        {
          variant: "gp_06",
        },
        {
          variant: "gp_07",
        },
        {
          variant: "gp_08",
        },
        {
          variant: "gp_09",
        },
        {
          variant: "gp_10",
        },
        {
          variant: "gp_11",
        },
        {
          variant: "gp_12",
        },
        {
          variant: "gp_13",
        },
        {
          variant: "gp_14",
        },
        {
          variant: "gp_15",
        },
        {
          variant: "gp_16",
        },
        {
          variant: "gp_17",
        },
        {
          variant: "gp_18",
        },
        {
          variant: "gp_19",
        },
        {
          variant: "gp_20",
        },
      ],
    },
    {
      pathname: "museum-night",
      name: "Museum night",
      description: [
        "“Museum nights” is an ongoing project telling stories of night guards at museums.",
      ],
      photos: [
        {
          variant: "mn_01",
          description: [
            "Sergei, night guard in the Museum of Hovhannes Tumanyan.",
            "“I have been working here since 1981. I left the job in 1985, but they called me back again in '93. Back in the 80s, Tumanyan's daughter lived here with her family, in these two rooms, which were closed for visitors. They always called me for tea at 7 o'clock. Many interesting people came to them as guests: artists, architects, painters… They were talking, and I was sitting and listening. ",
            'They used to say, "How can we celebrate New Year without you?" And we always celebrated the New Year together. But, to be honest, despite having worked here for so many years, I am not familiar with the exhibits. The thing I love the most in the museum is my room. There is nothing better than that.”',
          ],
        },
        {
          variant: "mn_02",
          description: [
            "Sergey, night guard at the Modern Art Museum.",
            "“I have been working here for ten years already. The location of our museum is a little out of the beaten track and there are constantly fights happening nearby. Every time we are afraid that they will break the glasses or something else.. Besides, our neighbours living on upper floors have the bad habit of flooding us, so we keep coming and going at night, so that if they do that again, we can protect the paintings at least.",
            "It was very interesting for me here at the beginning, I walked around, looked at the paintings. I have seen them so much now that I already know all of them. Most of all I like the paintings of Hakob Hakobyan, Minas and Elibekyan.”",
          ],
        },
        {
          variant: "mn_03",
          description: [
            "Martin, night guard at the Geological Museum.",
            "“I have been working at the Geological Institute since 1981, and I moved here a few years ago. The nights are quiet, and the museum is very interesting, we have such beautiful stones, even meteorites. Not to mention the mammoth. It’s a Mammoth's cub actually, he is from Gyumri, he has been found there. They say he was 4-5 years old.”",
          ],
        },
        {
          variant: "mn_04",
          description: [
            "Babken, night guard at Sergei Parajanov's Home Museum.",
            "“I have been working here for many years, but I would not call it an interesting job. Spend all night in solitude... I wish at least a friend was here with me.",
            "I do not have a special favorite from the museum exhibits, I kind of do not understand Parajanov's art. ",
            'Seems like it should be quite scary here at nights and in fact at the beginning there were so many strange sounds here at nights that I was like, "Who is this, what are they doing?". But then I got used to it little by little, began to understand where all of them come from, and the fear disappeared. Otherwise the nights are mostly quiet here. Many couples come to this small street in front of us, and in summer it becomes a street of love. Soon the weather will get hot and they will appear again.',
            "We also have a garden inside. The only sad thing is that our apricots will not yield this year. It is a very tasty apricot. The cherry will, but the apricot definitely will not.”",
          ],
        },
      ],
    },
    {
      pathname: "rethinking-quarantine",
      name: "Rethinking quarantine",
      description: [
        "Dictionary defines quarantine as a “state, period, or place of isolation in which people or animals that have arrived from elsewhere or been exposed to infectious or contagious disease are placed.”",
        "I spent 14 days locked in mandatory quarantine in Dilijan Armenia, after being evacuated because of the COVID 19 virus from Italy by a governmental flight, when all the flights to my country have been cancelled. And even after that it’s really difficult to understand what that word really means for me. Whether it is a state of mind, or a period of time spent locked, or just a place I spent it in. ",
        "Being limited physically in terms of one room and 4 walls seems to be a reason to become limited mentally. But instead it gave me an ununderstandable feeling of freedom and desire to go out of my boundaries and explore. That's why I decided to leave that room as a photographer staying isolated in the same time.Try to explore and expose my own isolation through the stories of my airplane neighbours who I couldn’t meet physically but with whom we had to share that experience of isolation in the same period of time and in the same location -building, divided by walls.",
      ],
      photos: [
        {
          variant: "rq_00",
          description: [
            "Marine Grigoryan,33 years old, opera singer currently living in Rome, Italy.",
            "“I was planning to isolate myself anyway even if they didn’t bring us here, the health of my relatives and people around me is very important to me. It’s called self responsibility.",
            "Being in quarantine I was really missing the ability to physically interact with people, items, everything. Now I miss the silence I used to have there.”",
          ],
        },
        {
          variant: "rq_01",
          description: [
            "Hayk Vardzelyan, 23 years old, marketing specialist, photographer, currently living in Abovyan, Armenia.",
            "“I was missing the active life I used to have before quarantine. The opportunity to go wherever I wanted, the freedom I had. 14 days is a long time to rethink some stuff. I started to value the ability of choosing how to spend my free time - to meet someone or to read something, maybe to sleep or to learn something new.”",
          ],
        },
        {
          variant: "rq_02",
          description: [
            "Tatyana Tevosyan, 63 years old, pensioner, currently living in Ejmiatsin, Armenia.",
            "“Nothing has changed for me with this quarantine. It was a little sad there, I missed my family but that’s all. I think if people now follow the rules and don’t go out of their houses unnecessarily then this situation will end in 15 days, but they don’t - so I don’t know how long this will last.”",
          ],
        },
        {
          variant: "rq_03",
          description: [
            "Harutyun Badeyan, 25 years old, student, currently living in Areco, Italy.",
            "“Alcohol is what I was really missing. Now I miss how careful people there in quarantine were. Two things these 14 days taught me is the importance of helping people you don’t know and to value my own life more. I think in two months I will be able to hug my friends, I truly believe in our health system.”",
          ],
        },
        {
          variant: "rq_04",
          description: [
            "Hayk Ayvazyan, 37 years old, cabin crew member of the aircraft, currently living in Yerevan, Armenia.",
            "“I learned that loneliness is actually something good. Opportunity to stay alone is what I will miss from there and maybe also the birds singing behind the windows.”",
          ],
        },
        {
          variant: "rq_05",
          description: [
            "Inesa Tadevosyan, 27 years old, literary and marketing specialist, currently living in Yerevan, Armenia.",
            "“There used to be a cafe in Yerevan named “Ilik”, spending time there on the balcony with my friend - is what I was missing most of all. Quarantine has made me a stronger person, taught me to be more patient. What am I going to miss from there? The doctors, the yummy food and the soft blanket they gave me which I sadly didn’t manage to take as it didn't fit in my luggage.”",
          ],
        },
        {
          variant: "rq_06",
          description: [
            "Arsen Abrahamyan, 21 years old, student, currently living in Ararat, Armenia.",
            "“Hugs. Obviously hugs are what I was missing most. After the quarantine I was ready to hug anything and anyone. I didn’t feel bad about being locked but from the other side I had to rethink the meaning of freedom. I’m not bored from staying alone with myself but it’s always good to have a choice.”",
          ],
        },
        {
          variant: "rq_07",
          description: [
            "Radik Sarukhanyan, 27 years old, student, currently living in Rome, Italy.",
            "“Doing nothing in quarantine for me was more like doing everything. I spent time trying to identify the real problems of my life and forget the difficulties of the past. The rush we have in our everyday lives doesn’t let you analyze what’s happening or make plans for the future. You can say that quarantine helped me reconcile with myself and achieve harmony.”",
          ],
        },
        {
          variant: "rq_08",
          description: [
            "Mary Ghazaryan, 22 years old, student, currently living in Rome, Italy.",
            "“Now when I’m out - I really miss the stuff of our quarantine: the doctors, the women who brought us food, my quarantine friends, my neighbour - who we communicated with through the balcony and the dog I used to feed every day. Being there - I missed the opportunity to just take a walk, nature, my working routine, but the time spent there taught me to appreciate small things in life which I didn't’ use to even notice before, things I thought were usual, even air for example.”",
          ],
        },
        {
          variant: "rq_09",
          description: [
            "Aram Arshakan, 23 years old, architect, currently living in Yerevan, Armenia.",
            "“The quarantine made me see how responsible my country is towards its citizens and made me become more responsible towards the government. I don’t know when this will end but I’m sure that it only depends on self- awareness of the population of my country, our responsibility and sense of civil debt.”",
          ],
        },
        {
          variant: "rq_10",
          description: [
            "Nvard Gambaryan, 21 years old, architecture student, currently living in Yerevan, Armenia.",
            "“Now I understand that no one is safe in situations like this and also that we are responsible not only for ourselves but for the people who surround us. It was emotionally very hard to know that all of the people I love are close but I’m not even allowed to leave my room. It was also a good test for my relationships with my friends as distance is always a test.”",
          ],
        },
      ],
    },
  ],
};

export const allProjects = () => {
  return {
    photos: [
      { variant: "slideshow_01" },
      { variant: "slideshow_02" },
      { variant: "slideshow_03" },
      { variant: "slideshow_04" },
      { variant: "slideshow_05" },
      { variant: "slideshow_06" },
      { variant: "slideshow_07" },
    ],
    name: "Biayna Mahari",
    description: ["Biayna Mahari"],
  };
};
