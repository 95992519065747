import React from "react";
import styled, { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  html, body, #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
    width: 100%;
    background-color: #f9f9f9;
  }
  body {
    margin: 0;
    font-family: Lato;
  }
  * {
    box-sizing: border-box;
  }
`;

const Main = styled.main`
  width: 100%;
  ${(p) => p.main && "height: 100%"};
`;

export const Layout = ({ children, main }) => (
  <>
    <GlobalStyles />
    <Main main={main}>{children}</Main>
  </>
);
