import * as React from "react";
import styled from "styled-components";

import { useOnClickOutside } from "../hooks/useOutsideClick";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { AiFillPrinter, AiOutlineInfo } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import { VscChromeClose } from "react-icons/vsc";
import {
  MdChevronLeft,
  MdChevronRight,
  MdApps,
  MdMenu,
  MdInfoOutline,
} from "react-icons/md";
import Hammer from "@egjs/hammerjs";
import { Helmet } from "react-helmet";
import { config, allProjects } from "../config/config";
import { Image } from "../components/Image";
import { Layout } from "../layout/Layout.js";

const Root = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`;

const MobileNav = styled.nav`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    display: none;
  }
`;

const Navigation = styled.nav`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-around;
`;

const InnerNavigation = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-around;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding-left: 10px;
  cursor: pointer;
  @media (min-width: 768px) {
    padding-left: 0;
  }
`;

const ProjectTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding-left: 10px;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    padding-left: 0;
  }
`;

const ImageMainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (min-width: 768px) {
    width: 65%;
    align-self: flex-start;
    justify-content: center;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 768px) {
    width: calc(100% - 15px);
    height: calc(100% - 40px);
    position: relative;
    .DescriptionContainer {
      opacity: ${({ descriptionOpened }) => (descriptionOpened ? 1 : 0)};
    }
    &:hover {
      .DescriptionContainer {
        ${(p) => !p.noHover && `opacity: 1;`};
      }
    }
  }
`;

const ImageInnerContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  overflow: hidden;
  margin-bottom: 10px;

  .gatsby-image-wrapper {
    width: 100%;
  }

  @media (min-width: 768px) {
    flex: 1;
    margin-bottom: 0;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  padding: 10px;
  overflow: visible;
  margin-bottom: 10px;
  @media (min-width: 768px) {
    align-items: center;
    font-size: 14px;
    width: unset;
    position: absolute;
    opacity: 0;
    padding: ${(p) => (p.fullScreen ? 0 : "50px")};
    color: black;
    margin-bottom: 0;
    ${(p) => (p.fullScreen ? "height: 100%" : 0)};
  }
  p {
    margin: 0;
  }
`;

const DescriptionInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0;
  background-color: #f9f9f9;
  @media (min-width: 768px) {
    padding: 60px;
    ${(p) => (p.fullScreen ? "height: 100%" : 0)};
    ${(p) => (p.fullScreen ? "justify-content: center" : 0)};
  }
`;

const ProjectsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Project = styled.div`
  cursor: pointer;
  width: 140px;
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 5px;
  ${(p) => p.isActive && `color: #999999;`};
  a {
    text-decoration: none;
    color: black;
    ${(p) => p.isActive && `color: #999999;`};
  }
`;

const MenuContainer = styled.div`
  display: flex;
  @media (min-width: 768px) {
    display: none;
  }
  padding: 20px 10px;
  cursor: pointer;
  box-sizing: border-box;
  svg {
    fill: #242424;
    width: 30px;
    height: 30px;
  }
`;

const LeftSideContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #f9f9f9;
  z-index: 20;
  ${(p) => (p.isVisible ? `display: flex` : `display: none`)};
  @media (min-width: 768px) {
    position: initial;
    width: 35%;
    display: flex;
  }
`;

const Arrows = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AllPhoto = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
`;

const ArrowContainer = styled.div`
  display: none;
  flex-wrap: wrap;
  width: 200px;
  padding: 0 5px;
  box-sizing: border-box;
  justify-content: center;
  margin-left: -13px;
  @media (min-width: 768px) {
    justify-content: flex-start;
  }
  > * {
    cursor: pointer;
  }
  @media (min-width: 768px) {
    display: flex;
  }
`;

const Thumbnails = styled.div`
  display: flex;
  width: calc(100% - 50px);
  height: calc(100% - 40px);
  overflow-x: scroll;
`;

const ThumbnailsInner = styled.div`
  display: flex;
`;

const Thumbnail = styled.div`
  position: relative;
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .gatsby-image-wrapper {
    height: 200px !important;
  }
  img {
    height: 200px !important;
  }
  .DescriptionContainer {
    opacity: 1;
  }
  width: 49%;
  @media (min-width: 768px) {
    height: 280px;
    width: 32%;
    .gatsby-image-wrapper {
      height: 280px !important;
    }
    img {
      height: 280px !important;
    }
  }
`;

const Block = styled.div`
  @media (max-width: 1280px) {
    width: 300px;
  }
  width: 400px;
`;

const PhotoClose = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  top: 15px;
  right: 15px;
  z-index: 100;
  color: black;
`;

const PhotoInfo = styled.div`
  margin-left: 5px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  color: black;
`;

const AboutMeContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    justify-content: center;
    font-size: 14px;
    width: 65%;
    padding: 30px;
    padding-right: 200px;
  }
`;

const AboutMeInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const AboutMeContent = styled.div`
  width: 100%;
  padding: 10px;

  @media (min-width: 768px) {
    width: 65%;
  }
`;

const AllPhotos = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  overflow-y: scroll;
  width: 100%;
  height: calc(100% - 40px);
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  .DescriptionContainer {
    opacity: 1;
  }
  position: relative;
`;

const FloatImageContainer = styled.div`
  width: 100%;
  text-align: justify;
  margin-bottom: 25px;
  @media (min-width: 768px) {
    margin: none;
    width: 35%;
    margin-right: 20px;
    margin-bottom: 20px;
  }
`;

const SmallImageContainer = styled.div`
  width: 100%;
  position: relative;
  @media (min-width: 768px) {
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const CenteredDiv = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
  a {
    color: black;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (min-width: 768px) {
    color: white;
    position: absolute;
    flex-direction: row;
    a {
      color: white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const BoldLi = styled.li`
  font-weight: 700;
`;

const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 100%;
  justify-content: space-around;
  height: 50px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
  li {
    font-weight: 700;
  }
`;

const Contacts = styled.div`
  a {
    color: black;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const MarginnedText = styled.div`
  margin-top: 5px;
`;

const BoldText = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const SocialMedias = styled.div`
  margin-top: 5px;
  font-weight: bold;
`;

const IndexPage = ({ data, fromContactMe }) => {
  const [all, setAll] = React.useState({ ...allProjects() });
  const [project, setProject] = React.useState({ ...all });
  const [photo, setPhoto] = React.useState(all.photos[0]);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [navIsVisible, setNavIsVisible] = React.useState(false);
  const imageRef = React.useRef(null);
  const [aboutMe, setAboutMe] = React.useState(false);
  const [shouldCarouselSlide, setShouldCarouselSlide] = React.useState(true);
  const [timeoutId, setTimeoutId] = React.useState(null);
  const [allPhotosOpen, setAllPhotosOpen] = React.useState(false);
  const [openedPhoto, setOpenedPhoto] = React.useState(null);
  const [descriptionOpened, setDescriptionOpened] = React.useState(false);
  const [isBiaynaMahari, setIsBiaynaMahari] = React.useState(true);
  const [delayedHover, setDelayedHover] = React.useState(false);
  const ref = React.useRef();
  const ref2 = React.useRef();
  useOnClickOutside(ref, () => {
    setDescriptionOpened(false);
    setShouldCarouselSlide(true);
  });
  useOnClickOutside(ref2, () => {
    setDescriptionOpened(false);
    setShouldCarouselSlide(true);
  });

  const resetAutoSlide = () => {
    clearTimeout(timeoutId);
    setShouldCarouselSlide(false);
    setTimeout(() => {
      setShouldCarouselSlide(true);
    }, 2000);
  };

  const delayHover = () => {
    setDelayedHover(true);
    setTimeout(() => {
      setDelayedHover(false);
    }, 3000);
  };

  React.useEffect(() => {
    setDescriptionOpened(false);
  }, [project]);

  React.useEffect(() => {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
  }, []);

  const handleNextPhoto = () => {
    const nextPhotoIndex = photoIndex + 1;
    if (project.photos[nextPhotoIndex]) {
      setPhoto({ ...project.photos[nextPhotoIndex] });
      setPhotoIndex(nextPhotoIndex);
    }
    resetAutoSlide();
  };

  const handleNextSlidePhoto = () => {
    const nextPhotoIndex = photoIndex + 1;
    if (project.photos[nextPhotoIndex]) {
      setPhoto({ ...project.photos[nextPhotoIndex] });
      setPhotoIndex(nextPhotoIndex);
    } else {
      setPhoto({ ...project.photos[0] });
      setPhotoIndex(0);
    }
    resetAutoSlide();
  };

  const handlePrevPhoto = () => {
    const nextPhotoIndex = photoIndex - 1;
    if (project.photos[nextPhotoIndex]) {
      setPhoto({ ...project.photos[nextPhotoIndex] });
      setPhotoIndex(nextPhotoIndex);
    }
    resetAutoSlide();
  };

  const handlePrevSlidePhoto = () => {
    const nextPhotoIndex = photoIndex - 1;
    if (project.photos[nextPhotoIndex]) {
      setPhoto({ ...project.photos[nextPhotoIndex] });
      setPhotoIndex(nextPhotoIndex);
    } else {
      setPhoto({ ...project.photos[project.photos.length - 1] });
      setPhotoIndex(project.photos.length - 1);
    }
    resetAutoSlide();
  };

  const setCurrentPhoto = (photo) => {
    setPhoto({ ...photo });
    const index = project.photos.findIndex(
      (item) => item.variant === photo.variant
    );
    setPhotoIndex(index);
    resetAutoSlide();
  };
  const handlePrevProject = () => {
    const currentProject = config.projects.findIndex(
      (p) => p.name === project.name
    );
    if (config.projects[currentProject - 1] && !aboutMe) {
      typeof window !== "undefined" &&
        window.history.pushState(
          "",
          "",
          `${config.projects[currentProject - 1].pathname}`
        );
      setProject({ ...config.projects[currentProject - 1] });
      setPhoto({ ...config.projects[currentProject - 1].photos[0] });
      setPhotoIndex(0);
    } else {
      if (currentProject === 0) {
        typeof window !== "undefined" &&
          window.history.pushState("", "", `contact-me`);
        setAboutMe(true);
        setProject({ ...config.projects[2] });
      } else {
        typeof window !== "undefined" &&
          window.history.pushState(
            "",
            "",
            `${config.projects[config.projects.length - 1].pathname}`
          );
        setProject({ ...config.projects[config.projects.length - 1] });
        setPhoto({ ...config.projects[config.projects.length - 1].photos[0] });
        setPhotoIndex(0);
        setAboutMe(false);
      }
    }
    resetAutoSlide();
  };

  const handleNextProject = () => {
    const currentProject = config.projects.findIndex(
      (p) => p.name === project.name
    );

    if (config.projects[currentProject + 1] && !aboutMe) {
      typeof window !== "undefined" &&
        window.history.pushState(
          "",
          "",
          `${config.projects[currentProject + 1].pathname}`
        );
      setProject({ ...config.projects[currentProject + 1] });
      setPhoto({ ...config.projects[currentProject + 1].photos[0] });
      setPhotoIndex(0);
    } else {
      if (currentProject + 1 === config.projects.length && !aboutMe) {
        typeof window !== "undefined" &&
          window.history.pushState("", "", `contact-me`);
        setAboutMe(true);
      } else {
        typeof window !== "undefined" &&
          window.history.pushState("", "", `${config.projects[0].pathname}`);
        setProject({ ...config.projects[0] });
        setPhoto({ ...config.projects[0].photos[0] });
        setPhotoIndex(0);
        setAboutMe(false);
      }
    }
    resetAutoSlide();
  };

  const handleArrows = (e) => {
    resetAutoSlide();
    if (e.keyCode === 37) {
      if (isBiaynaMahari) {
        handlePrevSlidePhoto();
      } else if (photoIndex !== 0) {
        handlePrevPhoto();
      }
    }
    if (e.keyCode === 39) {
      if (isBiaynaMahari) {
        handleNextSlidePhoto();
      } else if (photoIndex + 1 !== project.photos.length) {
        handleNextPhoto();
      }
    }
    if (typeof window !== "undefined" && window.innerWidth > 768) {
      clearTimeout(timeoutId);
      setShouldCarouselSlide(false);
      if (e.keyCode === 38) {
        handlePrevProject();
      }
      if (e.keyCode === 40) {
        handleNextProject();
      }
    }
  };

  const onProjectClick = React.useCallback(
    (p, shouldRedirect = true) => {
      clearTimeout(timeoutId);
      setShouldCarouselSlide(false);
      setAboutMe(false);
      setProject({ ...p });
      setPhoto({ ...p.photos[0] });
      setPhotoIndex(0);
      setNavIsVisible(false);
      setIsBiaynaMahari(false);
      setAllPhotosOpen(false);
      shouldRedirect &&
        typeof window !== "undefined" &&
        window.history.pushState("", "", `${p.pathname}`);
    },
    [timeoutId]
  );

  const onOpenAboutMe = () => {
    resetAutoSlide();
    setNavIsVisible(false);
    setAboutMe(true);
    setIsBiaynaMahari(false);
    typeof window !== "undefined" &&
      window.history.pushState("", "", "contact-me");
  };

  React.useEffect(() => {
    if (imageRef.current) {
      const hammer = Hammer(imageRef.current);
      hammer.on("swipeleft", handleNextPhoto);
      hammer.on("swiperight", handlePrevPhoto);
    }
  }, [imageRef, setPhotoIndex]);

  React.useEffect(() => {
    document.addEventListener("keydown", handleArrows, false);
    return () => {
      document.removeEventListener("keydown", handleArrows, false);
    };
  }, [photoIndex, project, aboutMe, isBiaynaMahari]);

  const selectProjectFromUrl = () => {
    const pathname =
      typeof window !== "undefined" &&
      window.location.pathname.replaceAll("/", "");
    const currentProject = config.projects.find((p) => p.pathname === pathname);

    if (fromContactMe) {
      onOpenAboutMe();
    } else if (currentProject) {
      onProjectClick(currentProject, false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("popstate", selectProjectFromUrl);
  }, []);

  React.useEffect(selectProjectFromUrl, []);

  React.useEffect(() => {
    if (shouldCarouselSlide && isBiaynaMahari) {
      const tm = setTimeout(() => {
        handleNextSlidePhoto();
      }, 3000);
      clearTimeout(timeoutId);
      setTimeoutId(tm);
    }
  }, [shouldCarouselSlide, photoIndex, isBiaynaMahari]);

  const noHover = !project.photos[photoIndex]?.description;
  const isMobile = typeof window !== "undefined" && window.innerWidth <= 768;

  return (
    <Layout main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Biayna Mahari Portfolio</title>
        <link rel="canonical" href="http://localhost:8000/" />
      </Helmet>
      <Root>
        <LeftSideContainer isVisible={navIsVisible}>
          <Navigation>
            <InnerNavigation>
              <Title
                onClick={() => {
                  resetAutoSlide();
                  const all = allProjects();
                  setAboutMe(false);
                  setProject({ ...all });
                  setPhotoIndex(0);
                  setNavIsVisible(false);
                  setPhoto({ ...all.photos[0] });
                  setIsBiaynaMahari(true);
                  typeof window !== "undefined" &&
                    window.history.pushState("", "", `/`);
                }}
              >
                Biayna Mahari
              </Title>
              <ProjectsContainer>
                {config.projects.map((p) => (
                  <Project
                    key={p.name}
                    isActive={!aboutMe && p.name === project.name}
                    onClick={() => onProjectClick(p)}
                  >
                    {p.name}
                  </Project>
                ))}
                <Project isActive={aboutMe} onClick={onOpenAboutMe}>
                  Contact me
                </Project>
              </ProjectsContainer>
            </InnerNavigation>
          </Navigation>
        </LeftSideContainer>
        {!aboutMe && (
          <ImageMainContainer>
            <>
              {!navIsVisible && (
                <MobileNav>
                  <Title
                    onClick={() => {
                      resetAutoSlide();
                      const all = allProjects();
                      setAboutMe(false);
                      setProject({ ...all });
                      setPhotoIndex(0);
                      setNavIsVisible(false);
                      setPhoto({ ...all.photos[0] });
                      setIsBiaynaMahari(true);
                      window.history.pushState("", "", `/`);
                    }}
                  >
                    Biayna Mahari
                  </Title>
                  <MenuContainer onClick={() => setNavIsVisible(!navIsVisible)}>
                    <MdMenu />
                  </MenuContainer>
                </MobileNav>
              )}
            </>
            {allPhotosOpen ? (
              <AllPhotos>
                {project.photos.map((item) => {
                  const itemCondition = item.variant === openedPhoto?.variant;

                  return (
                    <Thumbnail
                      isSelected={item.variant === photo.variant}
                      onClick={() => {
                        setCurrentPhoto(item);
                        setAllPhotosOpen(false);
                        delayHover();
                      }}
                    >
                      {!itemCondition && (
                        <Image variant={item.variant} alt={item.description} />
                      )}
                    </Thumbnail>
                  );
                })}
                {descriptionOpened && !isMobile && (
                  <DescriptionContainer className="DescriptionContainer">
                    <DescriptionInnerContainer ref={ref2}>
                      <PhotoClose
                        onClick={(e) => {
                          e.stopPropagation();
                          setDescriptionOpened(false);
                        }}
                      >
                        <VscChromeClose size={40} />
                      </PhotoClose>
                      {project.description?.map((item) => (
                        <p>{item}</p>
                      ))}
                    </DescriptionInnerContainer>
                  </DescriptionContainer>
                )}
              </AllPhotos>
            ) : isMobile ? (
              <>
                {!isBiaynaMahari && (
                  <>
                    <ProjectTitle>{project.name}</ProjectTitle>
                    {project.description &&
                      project.description instanceof Array && (
                        <DescriptionContainer className="DescriptionContainer">
                          <DescriptionInnerContainer>
                            {descriptionOpened && !isMobile && !allPhotosOpen && (
                              <PhotoClose
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDescriptionOpened(false);
                                }}
                              >
                                <VscChromeClose size={40} />
                              </PhotoClose>
                            )}
                            {project.description.map((item) => (
                              <p>{item}</p>
                            ))}
                          </DescriptionInnerContainer>
                        </DescriptionContainer>
                      )}
                  </>
                )}
                {project.photos.map((projectPhoto) => {
                  return (
                    <ImageContainer
                      ref={imageRef}
                      onMouseEnter={() => {
                        clearTimeout(timeoutId);
                        setShouldCarouselSlide(false);
                      }}
                      onMouseLeave={() => setShouldCarouselSlide(true)}
                      noHover={noHover}
                      descriptionOpened={descriptionOpened}
                    >
                      {projectPhoto && (
                        <ImageInnerContainer>
                          <Image
                            variant={projectPhoto.variant}
                            alt={projectPhoto.description}
                          />
                        </ImageInnerContainer>
                      )}
                      {!isBiaynaMahari && projectPhoto.description && (
                        <DescriptionContainer
                          className="DescriptionContainer"
                          isMobile
                        >
                          <DescriptionInnerContainer>
                            {projectPhoto.description &&
                              projectPhoto.description instanceof Array &&
                              projectPhoto.description.map((item) => (
                                <p>{item}</p>
                              ))}
                          </DescriptionInnerContainer>
                        </DescriptionContainer>
                      )}
                    </ImageContainer>
                  );
                })}
              </>
            ) : (
              <ImageContainer
                ref={imageRef}
                onMouseEnter={() => {
                  clearTimeout(timeoutId);
                  setShouldCarouselSlide(false);
                }}
                onMouseLeave={() => setShouldCarouselSlide(true)}
                noHover={noHover || delayedHover}
                descriptionOpened={descriptionOpened}
              >
                {photo && (
                  <ImageInnerContainer>
                    <Image variant={photo.variant} alt={photo.description} />
                  </ImageInnerContainer>
                )}
                <DescriptionContainer
                  className="DescriptionContainer"
                  fullScreen={!descriptionOpened}
                >
                  <DescriptionInnerContainer
                    ref={ref}
                    fullScreen={!descriptionOpened}
                  >
                    {descriptionOpened && !isMobile && !allPhotosOpen && (
                      <PhotoClose
                        onClick={(e) => {
                          e.stopPropagation();
                          setDescriptionOpened(false);
                        }}
                      >
                        <VscChromeClose size={40} />
                      </PhotoClose>
                    )}
                    {!descriptionOpened &&
                    photo.description &&
                    photo.description instanceof Array
                      ? photo.description.map((item) => <p>{item}</p>)
                      : project.description &&
                        project.description instanceof Array &&
                        project.description.map((item) => <p>{item}</p>)}
                  </DescriptionInnerContainer>
                </DescriptionContainer>
              </ImageContainer>
            )}
            {!isBiaynaMahari && (
              <ArrowContainer>
                <Arrows>
                  {project && photoIndex !== 0 && (
                    <MdChevronLeft
                      size={30}
                      left
                      onClick={() => {
                        handlePrevPhoto();
                      }}
                    >
                      -
                    </MdChevronLeft>
                  )}
                  {project && photoIndex + 1 !== project.photos.length && (
                    <MdChevronRight
                      size={30}
                      right
                      onClick={() => {
                        handleNextPhoto();
                      }}
                    >
                      -
                    </MdChevronRight>
                  )}
                </Arrows>
                <AllPhoto>
                  <MdApps
                    size={24}
                    onClick={() => setAllPhotosOpen(!allPhotosOpen)}
                  />
                </AllPhoto>
                {!!project.description && (
                  <PhotoInfo
                    onClick={() => {
                      setShouldCarouselSlide(false);
                      clearTimeout(timeoutId);
                      setDescriptionOpened(!descriptionOpened);
                    }}
                  >
                    <MdInfoOutline size={34} style={{ fontWeight: 400 }} />
                  </PhotoInfo>
                )}
              </ArrowContainer>
            )}
          </ImageMainContainer>
        )}
        {aboutMe && (
          <AboutMeContainer>
            {!navIsVisible && (
              <MobileNav>
                <Title
                  onClick={() => {
                    resetAutoSlide();
                    const all = allProjects();
                    setAboutMe(false);
                    setProject({ ...all });
                    setPhotoIndex(0);
                    setNavIsVisible(false);
                    setPhoto({ ...all.photos[0] });
                    setIsBiaynaMahari(true);
                    window.history.pushState("", "", `/`);
                  }}
                >
                  Biayna Mahari
                </Title>
                <MenuContainer onClick={() => setNavIsVisible(!navIsVisible)}>
                  <MdMenu />
                </MenuContainer>
              </MobileNav>
            )}
            <AboutMeInnerContainer>
              <FloatImageContainer>
                <Image
                  variant="contact"
                  alt="Biayna Mahari was born in Yerevan, Armenia in 1989."
                />
              </FloatImageContainer>
              <AboutMeContent>
                <BoldText>
                  Currently located between Armenia and Nagorno-Karabakh.
                </BoldText>
                Available for assignments in the region.
                <br />
                <br />
                <Contacts>
                  <a href="mailto:biayna.mahari@gmail.com">
                    biayna.mahari@gmail.com
                  </a>
                  <br />
                  <MarginnedText>+37455590229</MarginnedText>
                  <br />
                  <SocialMedias>
                    <a href="https://www.facebook.com/Zyuzya/">facebook</a> |{" "}
                    <a href="https://www.instagram.com/yozhezavrik/">
                      instagram
                    </a>
                  </SocialMedias>
                </Contacts>
              </AboutMeContent>
            </AboutMeInnerContainer>
          </AboutMeContainer>
        )}
      </Root>
    </Layout>
  );
};

export default IndexPage;
